import React, { useEffect, useState } from "react";

// Elements
import Accordion from 'react-bootstrap/Accordion';
import { Link, useParams } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Dropdown from 'react-bootstrap/Dropdown';

import { useDispatch, useSelector } from "react-redux";
import { listLogins, getUserLogins } from "../../../features/user/listLoginsHistorySlice";
import { formatDate, formatTime } from "../../../utilities/helper/formatDateTime";


const LOGINHISTORY = () => {
    const [id, setId] = useState(useParams().id)
    const [limit, setLimit] = useState(10)
    const [page, setPage] = useState(1)
    const [sortType, setSortType] = useState("desc")
    const [orderBy, setOrderBy] = useState("createdAt")
    const [perPage, setPerPage] = useState()
    const [total, setTotal] = useState()
    const [totalPages, setTotalPages] = useState()

    const params = {
        userId: id,
        limit,
        page,
        orderBy,
        sortType,
    }
    const dispatch = useDispatch();
    const loginsHistorySlice = useSelector(getUserLogins)
    const logins = loginsHistorySlice?.logins?.loginHistory;
    // console.log("These are the borrower login Slice....", loginsHistorySlice);
    // console.log("These are the borrower logins....", logins);

    useEffect(() => {
        (async () => {
            await dispatch(listLogins(params));

        })()
    }, [limit, page, orderBy, sortType]
    );
    useEffect(() => {
        setTotal(loginsHistorySlice?.logins?.total);
        setTotalPages(loginsHistorySlice?.logins?.totalPages);
    }, [loginsHistorySlice]
    );


    const reload = () => {

        if (page !== 1 || sortType !== "desc" || orderBy !== "createdAt") {
            setPage(1);
            setOrderBy("createdAt");
            setSortType("desc");
        } else {
            dispatch(listLogins(params));
        }

    }

    const handlePageNumber = (e) => {
        const value = e.currentTarget.value

        if (!isNaN(Number(value)) && Number(value) >= 1 && Number(value) <= totalPages) {
            setPage(value);
        }
    }


    const handlPrevClick = () => {
        const value = page;
        if (!isNaN(Number(value)) && Number(value) >= 1
            //  && Number(value) <= totalPages
        ) {
            if (Number(value) === 1) {
                setPage(1)
            } else {
                setPage(state => Number(state) - 1)
            }
        }
    }

    const handlNextClick = () => {
        const value = page;
        if (!isNaN(Number(value)) && Number(value) < totalPages && Number(value) >= 1) {
            setPage(state => Number(state) + 1)
            // if (Number(value) === totalPages) {
            //   setPage(totalPages)
            // }
        }
    }

    return (
        <>
            <div className="content">
                <div className="optionsBox">
                    <Row>
                        <Col lg="6">
                            <div className="searchBox">
                                <Form className="searchForm">
                                    <Form.Control className="" id="serachInput" placeholder="Search by Device ID" />
                                    <Button type="submit" className="">
                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M16.5 16.5L11.5 11.5M1.5 7.33333C1.5 8.09938 1.65088 8.85792 1.94404 9.56565C2.23719 10.2734 2.66687 10.9164 3.20854 11.4581C3.75022 11.9998 4.39328 12.4295 5.10101 12.7226C5.80875 13.0158 6.56729 13.1667 7.33333 13.1667C8.09938 13.1667 8.85792 13.0158 9.56565 12.7226C10.2734 12.4295 10.9164 11.9998 11.4581 11.4581C11.9998 10.9164 12.4295 10.2734 12.7226 9.56565C13.0158 8.85792 13.1667 8.09938 13.1667 7.33333C13.1667 6.56729 13.0158 5.80875 12.7226 5.10101C12.4295 4.39328 11.9998 3.75022 11.4581 3.20854C10.9164 2.66687 10.2734 2.23719 9.56565 1.94404C8.85792 1.65088 8.09938 1.5 7.33333 1.5C6.56729 1.5 5.80875 1.65088 5.10101 1.94404C4.39328 2.23719 3.75022 2.66687 3.20854 3.20854C2.66687 3.75022 2.23719 4.39328 1.94404 5.10101C1.65088 5.80875 1.5 6.56729 1.5 7.33333Z" stroke="url(#paint0_linear_15_1117_2)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            <defs>
                                                <linearGradient id="paint0_linear_15_1117_2" x1="1.46638" y1="1.79751" x2="15.8646" y2="16.1974" gradientUnits="userSpaceOnUse">
                                                    <stop stopColor="#236092" />
                                                    <stop offset="1" stopColor="#270089" />
                                                </linearGradient>
                                            </defs>
                                        </svg>
                                    </Button>
                                </Form>
                            </div>
                        </Col>

                        <Col lg="6">
                            <div className="pagination">
                                <span className="totalRecord me-3">{total ? total : "N/A"} Records</span>
                                <Form.Control
                                    className=""
                                    id="pageCount"
                                    placeholder=""
                                    value={page}
                                    onChange={handlePageNumber}

                                />
                                <span className="sep">- </span>
                                <span className="showRecord">{totalPages ? totalPages : "N/A"} &nbsp;&nbsp;&nbsp;</span>
                                {/* <span className="showRecord">50</span> */}
                                {/* <span className="of">of </span> */}
                                <span className="prePage" style={{ cursor: "pointer" }} onClick={handlPrevClick}>
                                    <svg
                                        width="26"
                                        height="26"
                                        viewBox="0 0 26 26"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <rect
                                            x="0.5"
                                            y="0.5"
                                            width="25"
                                            height="25"
                                            rx="12.5"
                                            stroke="#696969"
                                        />
                                        <g clipPath="url(#clip0_0_1)">
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M9.95496 12.205C9.74429 12.4159 9.62595 12.7018 9.62595 13C9.62595 13.2981 9.74429 13.584 9.95496 13.795L14.197 18.0385C14.408 18.2494 14.6942 18.3679 14.9926 18.3678C15.291 18.3677 15.5771 18.2491 15.7881 18.0381C15.999 17.827 16.1175 17.5408 16.1174 17.2424C16.1174 16.944 15.9988 16.6579 15.7877 16.447L12.3407 13L15.7877 9.55295C15.9927 9.34087 16.1063 9.05675 16.1038 8.76177C16.1014 8.4668 15.9832 8.18458 15.7748 7.97589C15.5663 7.76721 15.2842 7.64876 14.9892 7.64606C14.6942 7.64336 14.41 7.75662 14.1977 7.96145L9.95421 12.2042L9.95496 12.205Z"
                                                fill="#696969"
                                            />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_0_1">
                                                <rect
                                                    width="18"
                                                    height="18"
                                                    fill="white"
                                                    transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 22 22)"
                                                />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </span>
                                <span className="nextPage" style={{ cursor: "pointer" }} onClick={handlNextClick}>
                                    <svg
                                        width="26"
                                        height="26"
                                        viewBox="0 0 26 26"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <rect
                                            x="0.5"
                                            y="0.5"
                                            width="25"
                                            height="25"
                                            rx="12.5"
                                            stroke="#696969"
                                        />
                                        <g clipPath="url(#clip0_0_1)">
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M16.045 12.205C16.2557 12.4159 16.374 12.7018 16.374 13C16.374 13.2981 16.2557 13.584 16.045 13.795L11.803 18.0385C11.592 18.2494 11.3058 18.3679 11.0074 18.3678C10.709 18.3677 10.4229 18.2491 10.2119 18.0381C10.001 17.827 9.8825 17.5408 9.88257 17.2424C9.88264 16.944 10.0012 16.6579 10.2123 16.447L13.6593 13L10.2123 9.55295C10.0073 9.34087 9.89373 9.05675 9.89615 8.76177C9.89858 8.4668 10.0168 8.18458 10.2252 7.97589C10.4337 7.76721 10.7158 7.64876 11.0108 7.64606C11.3058 7.64336 11.59 7.75662 11.8023 7.96145L16.0458 12.2042L16.045 12.205Z"
                                                fill="#696969"
                                            />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_0_1">
                                                <rect
                                                    width="18"
                                                    height="18"
                                                    fill="white"
                                                    transform="translate(4 22) rotate(-90)"
                                                />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </span>

                                <span className="reloadList" style={{ cursor: "pointer" }} onClick={reload}>
                                    <svg fill="#696969" height="12" width="12" viewBox="0 0 489.711 489.711">
                                        <path d="M112.156,97.111c72.3-65.4,180.5-66.4,253.8-6.7l-58.1,2.2c-7.5,0.3-13.3,6.5-13,14c0.3,7.3,6.3,13,13.5,13 c0.2,0,0.3,0,0.5,0l89.2-3.3c7.3-0.3,13-6.2,13-13.5v-1c0-0.2,0-0.3,0-0.5v-0.1l0,0l-3.3-88.2c-0.3-7.5-6.6-13.3-14-13 c-7.5,0.3-13.3,6.5-13,14l2.1,55.3c-36.3-29.7-81-46.9-128.8-49.3c-59.2-3-116.1,17.3-160,57.1c-60.4,54.7-86,137.9-66.8,217.1 c1.5,6.2,7,10.3,13.1,10.3c1.1,0,2.1-0.1,3.2-0.4c7.2-1.8,11.7-9.1,9.9-16.3C36.656,218.211,59.056,145.111,112.156,97.111z"></path>
                                        <path d="M462.456,195.511c-1.8-7.2-9.1-11.7-16.3-9.9c-7.2,1.8-11.7,9.1-9.9,16.3c16.9,69.6-5.6,142.7-58.7,190.7 c-37.3,33.7-84.1,50.3-130.7,50.3c-44.5,0-88.9-15.1-124.7-44.9l58.8-5.3c7.4-0.7,12.9-7.2,12.2-14.7s-7.2-12.9-14.7-12.2l-88.9,8 c-7.4,0.7-12.9,7.2-12.2,14.7l8,88.9c0.6,7,6.5,12.3,13.4,12.3c0.4,0,0.8,0,1.2-0.1c7.4-0.7,12.9-7.2,12.2-14.7l-4.8-54.1 c36.3,29.4,80.8,46.5,128.3,48.9c3.8,0.2,7.6,0.3,11.3,0.3c55.1,0,107.5-20.2,148.7-57.4 C456.056,357.911,481.656,274.811,462.456,195.511z"></path>
                                    </svg>
                                </span>
                            </div>
                        </Col>
                    </Row>
                </div>

                <div className="records activeLoanApp">
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>
                                    <div className="labelIco">
                                        <span className="headTitle">Attempt At</span>
                                        <span className="icon">
                                            <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.2955 11.2C9.67869 11.2 9.36829 11.944 9.80269 12.3824L12.8875 15.4968C13.2803 15.8928 13.9203 15.8928 14.3131 15.4968L17.3979 12.3824C17.8323 11.944 17.5219 11.2 16.9051 11.2H10.2955Z" fill="#270089" />
                                                <path d="M15.2002 12C15.2002 12 15.2002 6.95765 15.2002 6.40005C15.2002 5.51605 14.4842 4.80005 13.6002 4.80005C12.7162 4.80005 12.0002 5.51605 12.0002 6.40005C12.0002 6.95765 12.0002 12 12.0002 12H15.2002Z" fill="#270089" />
                                                <path d="M13.6002 3.2C14.4839 3.2 15.2002 2.48366 15.2002 1.6C15.2002 0.716344 14.4839 0 13.6002 0C12.7166 0 12.0002 0.716344 12.0002 1.6C12.0002 2.48366 12.7166 3.2 13.6002 3.2Z" fill="#270089" />
                                                <path opacity="0.35" d="M7.79779 3.6177L4.713 0.503299C4.32019 0.107299 3.6802 0.107299 3.2874 0.503299L0.202595 3.6177C-0.231805 4.0561 0.0785954 4.8001 0.695395 4.8001H2.4002V9.6001C2.4002 10.4841 3.1162 11.2001 4.0002 11.2001C4.88419 11.2001 5.60019 10.4841 5.60019 9.6001V4.8001H7.30499C7.92179 4.8001 8.23219 4.0561 7.79779 3.6177Z" fill="#270089" />
                                                <path opacity="0.35" d="M4.00015 16C4.8838 16 5.60015 15.2837 5.60015 14.4C5.60015 13.5164 4.8838 12.8 4.00015 12.8C3.11649 12.8 2.40015 13.5164 2.40015 14.4C2.40015 15.2837 3.11649 16 4.00015 16Z" fill="#270089" />
                                            </svg>
                                        </span>
                                    </div>
                                </th>


                                <th>
                                    <div className="labelIco">
                                        <span className="headTitle">Status</span>
                                    </div>
                                </th>

                                <th>
                                    <div className="labelIco">
                                        <span className="headTitle">Device ID</span>
                                    </div>
                                </th>

                                <th>
                                    <div className="labelIco">
                                        <span className="headTitle">Longitude</span>
                                    </div>
                                </th>

                                <th>
                                    <div className="labelIco">
                                        <span className="headTitle">Latitude</span>
                                    </div>
                                </th>

                                <th>
                                    <div className="labelIco">
                                        <span className="headTitle">IP Address</span>
                                    </div>
                                </th>

                                <th>
                                    <div className="labelIco">
                                        <span className="headTitle">Reason</span>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {loginsHistorySlice?.loading && <div className="notifyNoRecord">Loading...</div>}
                            {!loginsHistorySlice?.loading && loginsHistorySlice?.error ? <div>Error: {loginsHistorySlice?.error}</div> : null}

                            {!loginsHistorySlice?.loading && logins?.length ?
                                (logins.map((login, index) => (
                                    <tr key={index}>
                                        <td>
                                            <div className="dateTime">
                                                <span className="date">{login.createdAt ? formatDate(login.createdAt) : "N/A"}</span><br />
                                                <span className="time">{login.createdAt ? formatTime(login.createdAt) : ""}</span>
                                            </div>
                                        </td>


                                        <td>
                                            <div className="status">
                                                <span>{login.status ? login?.status : "N/A"}</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="device-id">
                                                <span>{login.deviceId ? login?.deviceId : "N/A"}</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="session-id">
                                                <span>{login.lng ? login?.lng : "N/A"}</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="session-id">
                                                <span>{login.lng ? login?.lat : "N/A"}</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="ip-address">
                                                <span>{login.ipAddress ? login?.ipAddress : "N/A"}</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="ip-address">
                                                <span>{login.reason ? login?.reason : "N/A"}</span>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                                ) : null
                            }

                        </tbody>
                    </Table>

                    {!loginsHistorySlice?.loading && !logins?.length && !loginsHistorySlice?.error && <><div className="notifyNoRecord">Data doesn't exist!</div></>}
                </div>
            </div>
        </>
    )
}

export default LOGINHISTORY