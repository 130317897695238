import React from "react";
import { useState, useEffect } from "react";
import { showLenderAnalytics, getLenderAnalytics } from "../../../features/lender/lenderAnalyticsSlice";
import { useDispatch, useSelector } from "react-redux";

const COUNTER = () => {

    const dispatch = useDispatch();
    const counterSlice = useSelector(getLenderAnalytics)
    const analytics = counterSlice?.analytics;

    // console.log("======The Lender Counter Slice======", counterSlice);
    // console.log("======The Lender Analytics======", analytics);
    const params = {
        userType: "investor"
    }

    useEffect(() => {
        (async () => {
            await dispatch(showLenderAnalytics(params))
        })()
    }, []
    );

    return (
        <>
            <div className="counter">
                <div className="flex-counter">
                    <div className="countBox">
                        <h6>Total Lender</h6>
                        <h1>{analytics?.usersCount ? analytics?.usersCount : "N/A"}</h1>
                    </div>

                    <div className="countBox">
                        <h6>Active Lenders</h6>
                        <h1>{analytics?.activeUsersCount ? analytics?.activeUsersCount : "N/A"}</h1>
                    </div>

                    <div className="countBox">
                        <h6>Sanctioned</h6>
                        <h1>{analytics?.sanctioned ? analytics?.sanctioned : "N/A"}</h1>
                    </div>

                    <div className={`countBox `}>
                        <h6>Banned</h6>
                        <h1>{analytics?.bannedUsersCount ? analytics?.bannedUsersCount : "N/A"}</h1>
                    </div>

                    <div className={`countBox`}>
                        <h6>PEP Type</h6>
                        <h1>{analytics?.pepType ? analytics?.pepType : "N/A"}</h1>
                    </div>
                </div>
            </div>
        </>
    )
}

export default COUNTER