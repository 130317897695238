import React, { useEffect, useState } from "react";
import axios from "axios";
import "../../assets/css/header/header.css";
import profileimg from "../../assets/images/pro-img.png";
import { useDispatch, useSelector } from "react-redux";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Dropdown from "react-bootstrap/Dropdown";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import { postAPI } from "../../api/apiCallMethods";

import userRoleVar from "../../utilities/global/userRole";
import { formatDate, formatTime } from "../../utilities/helper/formatDateTime";
import { jwtDecode } from "jwt-decode";
import { getProfileDetails, showProfileDetails } from "../../features/user/profileSlice";
const ProfileImage = ({ name, className }) => {
  const nameParts = name.split(" ");
  const firstNameInitial = nameParts[0] ? nameParts[0][0] : "";
  const lastNameInitial = nameParts[1] ? nameParts[1][0] : "";

  return (
    <span className={className}>
      {firstNameInitial}
      {lastNameInitial}
    </span>
  );
};
const HEADER = () => {
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}|:<>?~`\-=[\]\\;',./]).{8,14}$/;
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [displayName, setDisplayName] = useState("");
  const [displayRole, setDisplayRole] = useState("");
  const [userRoles, setUserRoles] = useState();
  const [userId, setUserId] = useState();
  const [userData, setUserData] = useState();
  const [tokenData, setTokenData] = useState();

  const [error, setError] = useState();
  // const user = useSelector((state) => state.user.user)
  // ? useSelector((state) => state.user.user)
  // : { name: "Test Name", role: "Super Admin" };

  const userRoleVarObject = userRoleVar();

  const user = useSelector((state) => state.user.user) || {
    name: "John Doe",
    role: "Super Admin",
  };

  const handleLogoutClick = (e) => {
    localStorage.removeItem("userToken");
    localStorage.removeItem("userRoles");
    localStorage.removeItem("userRoleId");
    localStorage.removeItem("userName");
    localStorage.removeItem("userId");
  };

  const dispatch = useDispatch();
  const profileDetailsSlice = useSelector(getProfileDetails)
  const profileDetails = profileDetailsSlice?.profileDetails;



  useEffect(() => {
    let memberUserId = localStorage.getItem("userId");
    let userMetaData = localStorage.getItem("metaData");
    const parsedUserData = JSON.parse(userMetaData);

    const token = localStorage.getItem("userToken");
    const metaData = jwtDecode(token);

    (async () => {
      await dispatch(showProfileDetails({
        userId: memberUserId
      }));
    })()
    setUserId(memberUserId);
    setUserData(parsedUserData);

    setTokenData(metaData);

  }, []);

  const [formData, setFormData] = useState({
    currentPass: "",
    newPass: "",
    confirmPass: "",
  });


  const [btnDisabled, setBtnDisabled] = useState(true)

  // const handleInputChange = (e) => {
  //   const { id, value } = e.target;
  //   console.log(id, value);
  //   setFormData({ ...formData, [id]: value });
  // };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData(prevFormData => ({ ...prevFormData, [id]: value }));



    // let passw = /^[A-Za-z]\w{7,14}$/;

    //setFormData({ ...formData, [id]: value });


    // if (e.target.value.match(passw) && formData.newPass != "") {
    //   setError("");
    // } else {
    //   setError("Try different password");
    //   setBtnDisabled(true);

    // }

  };
  useEffect(() => {
    const { currentPass, newPass, confirmPass } = formData;

    if (currentPass !== "") {
      if (passwordRegex.test(newPass)) {
        setError("");
        if (newPass === confirmPass) {
          setError("");
          setBtnDisabled(false);
        } else {
          setError("Both passwords should match.");
          setBtnDisabled(true);
        }
      } else {
        setError("Invalid password format.");
      }
    }
  }, [formData])
  // useEffect(() => {
  //   const { currentPass, newPass, confirmPass } = formData;

  //   if (currentPass !== "") {
  //     if (passwordRegex.test(newPass)) {
  //       setError("")
  //       if (newPass === confirmPass) {
  //         setError("")
  //         setBtnDisabled(false);
  //       } else {
  //         setError("Both passwords should match.")
  //         setBtnDisabled(true);
  //       }
  //     } else {
  //       setError("Both passwords should match.")
  //       setBtnDisabled(true);
  //     }
  //   }
  // }, [formData])

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Make a POST request to your API endpoint
      console.log(formData);

      if (formData["newPass"] === formData["confirmPass"]) {
        let resp = await postAPI(
          process.env.REACT_APP_BASE_URL +
          process.env.REACT_APP_ADMIN_UPDATE_PASSWORD,
          {
            userId: userId,
            password: formData["confirmPass"],
            oldPassword: formData["currentPass"],
          }
        );
        console.log("API Response:", resp.data);
        // Close the modal after successful submission
        if (resp.data.code === 400) {
          setError(resp.data.message);

        } else {
          handleClose();
        }
        setFormData({
          currentPass: "",
          newPass: "",
          confirmPass: "",
        });
      } else {
        // console.log("Error: New Password and confirm Password not matched!");
        setError(
          "New Password and confirm Password is not matched. Kindly Try Again!"
        );
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // User Roles
  const [useRoleData, setuseRoleData] = useState([]);
  const [useRoleId, setuseRoleId] = useState();

  useEffect(() => {
    // Retrieve data from localStorage
    const token = localStorage.getItem("userToken");
    const decoded = jwtDecode(token);
    const storedUserData = localStorage.getItem("userRoles");
    const storedUserId = decoded.roleId;

    setuseRoleId(decoded.roleId);
    // console.log(decoded, "header role id");

    setDisplayName(decoded.name);
    setDisplayRole(decoded.roleId);

    if (storedUserData) {
      // Parse the stored data
      const parsedUserData = JSON.parse(storedUserData);
      // console.log("header parsedUserData======", parsedUserData)

      // Filter the parsed data based on roleId
      const filteredRoles = parsedUserData.filter(
        (role) => role.roleId == storedUserId
      );
      if (filteredRoles.length > 0) {
        // If there are roles with the specified roleId
        const filterRoleData = filteredRoles[0];
        setuseRoleData(filterRoleData); // Set the role data
      } else {
        // If there are no roles with the specified roleId
        console.log("Header: No role data found for the specified roleId.");
      }
    }
  }, [useRoleId]);

  return (
    <>
      <div className="header">
        <Dropdown className="profileDropdown" align={{ lg: "end" }}>
          <Dropdown.Toggle variant="" id="dropdown-basic">
            <div className="profile-flex">
              <figure>
                <ProfileImage className="user-profile-image" name={displayName ?? user.name} />
              </figure>

              <div className="titleBox">
                {/* <h3>{user.name}</h3> */}
                {displayName === " " ? (
                  <>
                    <h3>{user.name}</h3>
                  </>
                ) : (
                  <>
                    <h3>{displayName}</h3>
                  </>
                )}

                {/* <p>{user.role}</p> */}
                {/* <div>
                  {displayRole === 1 ? (
                    <p>{userRoleVarObject.key0}</p>
                  ) : displayRole === 2 ? (
                    <p>{userRoleVarObject.key1}</p>
                  ) : displayRole === 3 ? (
                    <p>{userRoleVarObject.key2}</p>
                  ) : null}
                </div> */}
              </div>
            </div>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item href="#/action-2" onClick={handleShow}>
              <span className="linkWithIcon">
                <figure>
                  <svg
                    width="100%"
                    height="auto"
                    viewBox="0 0 13 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_2669_35363)">
                      <path
                        d="M6.12524 7C7.0535 7 7.94374 6.63125 8.60012 5.97487C9.2565 5.3185 9.62524 4.42826 9.62524 3.5C9.62524 2.57174 9.2565 1.6815 8.60012 1.02513C7.94374 0.368749 7.0535 0 6.12524 0C5.19699 0 4.30675 0.368749 3.65037 1.02513C2.99399 1.6815 2.62524 2.57174 2.62524 3.5C2.62524 4.42826 2.99399 5.3185 3.65037 5.97487C4.30675 6.63125 5.19699 7 6.12524 7ZM4.87563 8.3125C2.18228 8.3125 0.000244141 10.4945 0.000244141 13.1879C0.000244141 13.6363 0.363916 14 0.812354 14H11.4381C11.8866 14 12.2502 13.6363 12.2502 13.1879C12.2502 10.4945 10.0682 8.3125 7.37485 8.3125H4.87563Z"
                        fill="#535252"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_2669_35363">
                        <rect
                          width="12.25"
                          height="14"
                          fill="white"
                          transform="translate(0.000244141)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </figure>
                <span className="label">Edit Profile</span>
              </span>
            </Dropdown.Item>

            <Dropdown.Item href="/" onClick={handleLogoutClick}>
              <span className="linkWithIcon">
                <figure>
                  <svg
                    width="100%"
                    height="auto"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_2669_35366)">
                      <path
                        d="M13.7432 7.61797C14.085 7.27617 14.085 6.72109 13.7432 6.3793L10.2432 2.8793C9.90142 2.5375 9.34634 2.5375 9.00454 2.8793C8.66274 3.22109 8.66274 3.77617 9.00454 4.11797L11.0116 6.125H5.25024C4.76626 6.125 4.37524 6.51602 4.37524 7C4.37524 7.48398 4.76626 7.875 5.25024 7.875H11.0116L9.00454 9.88203C8.66274 10.2238 8.66274 10.7789 9.00454 11.1207C9.34634 11.4625 9.90142 11.4625 10.2432 11.1207L13.7432 7.6207V7.61797ZM4.37524 2.625C4.85923 2.625 5.25024 2.23398 5.25024 1.75C5.25024 1.26602 4.85923 0.875 4.37524 0.875H2.62524C1.17603 0.875 0.000244141 2.05078 0.000244141 3.5V10.5C0.000244141 11.9492 1.17603 13.125 2.62524 13.125H4.37524C4.85923 13.125 5.25024 12.734 5.25024 12.25C5.25024 11.766 4.85923 11.375 4.37524 11.375H2.62524C2.14126 11.375 1.75024 10.984 1.75024 10.5V3.5C1.75024 3.01602 2.14126 2.625 2.62524 2.625H4.37524Z"
                        fill="#535252"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_2669_35366">
                        <rect
                          width="14"
                          height="14"
                          fill="white"
                          transform="translate(0.000244141)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </figure>
                <span className="label">Logout</span>
              </span>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        <Modal
          show={show}
          onHide={handleClose}
          size="xl"
          dialogClassName="modal-90w"
          aria-labelledby="contained-modal-title-vcenter"
          className="banForm editProfile"
        >
          <Modal.Header closeButton>
            <Modal.Title className="mb-4">Edit Profile</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Form onSubmit={handleSubmit}>
              <Row className="mb-4">


                <Col className="mb-2">
                  <div className="popupDv">
                    <h4>User ID</h4>
                    {/* <h5>0152150111</h5> */}
                    <h5>{userId}</h5>
                  </div>
                </Col>


                <Col className="mb-2">
                  <div className="popupDv">
                    <h4>Current Status:</h4>
                    {/* <h5>Active</h5> */}
                    <h5>
                      <div className="status">
                        <span
                          className={`dot ${userData?.isLocked === 1 ? "banned" : "active"
                            }`}
                        ></span>
                        {/* <span>Active</span> */}

                        <span>
                          {userData?.isLocked === "1" ? "Not Active" : "Active"}
                        </span>
                      </div>
                    </h5>
                  </div>
                </Col>

                <Col className="mb-2">
                  <div className="popupDv">
                    <h4>Role</h4>
                    <h5>
                      {displayRole == "1" ? (
                        <p>{userRoleVarObject.key0}</p>
                      ) : displayRole == "2" ? (
                        <p>{userRoleVarObject.key1}</p>
                      ) : displayRole == "3" ? (
                        <p>{userRoleVarObject.key2}</p>
                      ) : null}
                    </h5>
                  </div>
                </Col>
              </Row>

              <hr className="dvdr" />

              <Row className="mb-4">
                <Col lg="2">
                  <div className="imageUpload">
                    <div className="imageBg">
                      <ProfileImage className="user-profile-image-xl" name={profileDetails?.firstName} />
                    </div>
                  </div>
                </Col>

                <Col lg="10">
                  <Row>
                    <Col lg="6" className="mb-4">
                      <div className="popupDv">
                        <h4>User Name</h4>
                        <h5>{profileDetails?.firstName ?? ""} {profileDetails?.secondName ?? ""} {profileDetails?.lastName ?? ""}</h5>
                      </div>
                    </Col>

                    <Col lg="6" className="mb-4">
                      <div className="popupDv">
                        <h4>User Phone No.</h4>
                        <h5>{profileDetails?.phoneNumber}</h5>
                      </div>
                    </Col>

                    <Col lg="12">
                      <div className="popupDv">
                        <h4>User Email</h4>
                        <h5>{profileDetails?.phoneNumber}</h5>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <div className="labelSeparator mb-4">
                <span className="label">Change password</span>
                <hr className="dvdr ms-2" />
              </div>

              <Row className="mb-2">
                <Col lg="4">
                  <Form.Group className="mb-3" controlId="currentPass">
                    <Form.Label>Current Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Enter your current password"
                      onChange={handleInputChange}
                      value={formData.currentPass}
                    />
                  </Form.Group>
                </Col>

                <Col lg="4">
                  <Form.Group className="mb-3" controlId="newPass">
                    <Form.Label>New Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Enter your new password"
                      onChange={handleInputChange}
                      value={formData.newPass}
                    />
                  </Form.Group>
                </Col>

                <Col lg="4">
                  <Form.Group className="mb-3" controlId="confirmPass">
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Enter your confirm password"
                      onChange={handleInputChange}
                      value={formData.confirmPass}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <div className="msgnote">
                <p>Password must contain at least one lowercase letter, one uppercase letter, one digit, one special character, and be between 8 and 14 characters long.</p>
              </div>
              <div className="msg" style={{ color: "red", fontWeight: "bold" }}>
                {error}
              </div>

              <div className="actionBtns mt-4">
                <Button
                  variant=""
                  className="btn-theme-primary me-3"
                  onClick={handleClose}
                >
                  Close
                </Button>
                <Button
                  variant=""
                  className={`btn-theme-secondary ${btnDisabled ? "" : "active"}`}
                  type="submit"
                  disabled={btnDisabled}
                >
                  Save changes
                </Button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>



        <Modal
          show={false}
          size="sm"
          dialogClassName="modal-90w"
          aria-labelledby="contained-modal-title-vcenter"
          className="banForm"
        >
          <Modal.Header closeButton={false}>
            <Modal.Title className="mb-4">Session Timeout</Modal.Title>
          </Modal.Header>

          <Modal.Body>

            <Row className="mb-4">
              <Col className="mb-2">
                <p>Your session has expired please login again.</p>
              </Col>

            </Row>

            <div className="actionBtns mt-4">
              <Button
                variant=""
                className="btn-theme-primary me-3"
              >
                OK
              </Button>
            </div>

          </Modal.Body>
        </Modal>

      </div>
    </>
  );
};

export default HEADER;
