import React, { useEffect, useState } from "react";


// Elements
import Accordion from 'react-bootstrap/Accordion';
import { Link, useLocation } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Dropdown from 'react-bootstrap/Dropdown';

// Components
import HEADER from "../../component/header/header";
import BREADCRUMB from "../../component/breadcrumb/breadcrumb";
import INFO from "../../component/pending-registeration-approvals/info/info";
import SIDEBAR from "../../component/sidebar/sidebar";

import { useDispatch, useSelector } from "react-redux";
import { showPepDetails, getPepDetails } from "../../features/pending-registeration-approvals/pendingRegApprovalDetailsSlice"
import { formatDate, formatTime } from "../../utilities/helper/formatDateTime";
import formattedNumber from "../../utilities/helper/formatAmount/formatNumber";
import { useNavigate, useParams } from 'react-router-dom';
import { api, postAPI } from "../../api/apiCallMethods";
import { jwtDecode } from "jwt-decode";
import useAuth from "../../hooks/permission-hook";

const PENDINGREGISTERATIONDETAIL = () => {
    const navigate = useNavigate();
    const [id, setId] = useState(useParams().id);
    const dispatch = useDispatch();
    const pepDetailsSlice = useSelector(getPepDetails)
    const pepDetails = pepDetailsSlice?.pepDetails;
    const location = useLocation();
    const { regDate, category, userType } = location?.state ? location?.state : {};
    // console.log({ regDate, category, userType })


    // const [reason, setReason] = useState("");
    const [comments, setComments] = useState("");

    // console.log("show pepdetails === >",pepDetails);

    const params = {
        userId: id
    }

    useEffect(() => {
        (async () => {
            dispatch(showPepDetails(params));
        })()
    }, []);

    // console.log("Here are the pep details form pep details component mount", pepDetails);

    const [showUserPep, setshowUserPep] = useState(false);
    const [userActionPep, setUserActionPep] = useState("button");

    const handleCloseUserPep = () => setshowUserPep(false);
    // const handleShow = () => setShow(true);

    const handleShowUserPep = () => {
        setshowUserPep(true);
    }

    // get select value 
    const [selectUserPep, showSelectUserPep] = useState('');

    const HandleSelectUserPep = (event) => {
        showSelectUserPep(event.target.value);
        console.log(event.target.value);
    }

    const handleActionUserPep = async () => {

        const reqConfig = {
            method: "POST",
            url: process.env.REACT_APP_BASE_URL + process.env.REACT_APP_PEP_ACTION,
            data: {
                action: selectUserPep,
                comment: comments,
                userId: id,
            }
        };
        await api(reqConfig);

        // await postAPI(process.env.REACT_APP_BASE_URL + process.env.REACT_APP_PEP_ACTION, {
        //     action: selectUserPep,
        //     banComment: comments,
        //     id
        // });
        showSelectUserPep("")
        setComments("");
        dispatch(showPepDetails(params));
        setshowUserPep(false);
        navigate("/pending-registeration")
    }

    // user roles
    const [useRoleData, setuseRoleData] = useState([]);
    const [useRoleId, setuseRoleId] = useState();

    // useEffect(() => {
    //     // Retrieve data from localStorage
    //     const storedUserData = localStorage.getItem("userRoles");
    //     const storedUserId = localStorage.getItem("userRoleId");

    //     setuseRoleId(storedUserId);

    //     if (storedUserData) {
    //         // Parse the stored data
    //         const parsedUserData = JSON.parse(storedUserData);
    //         // Find the object with the specified role ID
    //         const filteredRole = parsedUserData.find(role => role.roleId === useRoleId);

    //         if (filteredRole) {
    //             // If a role with the specified ID is found, set the state with that role object
    //             setuseRoleData(filteredRole);
    //         } else {
    //             // If no role with the specified ID is found
    //             console.log("No role data found for the specified role ID.");
    //         }
    //     }
    // }, [useRoleId]); 

    useEffect(() => {
        // Retrieve data from localStorage
        const token = localStorage.getItem("userToken");
        const decoded = jwtDecode(token);
        const storedUserData = localStorage.getItem("userRoles");
        const storedUserId = decoded.roleId;

        setuseRoleId(decoded.roleId);

        if (storedUserData) {
            // Parse the stored data
            const parsedUserData = JSON.parse(storedUserData);
            //console.log("parsedUserData======", parsedUserData)

            // Filter the parsed data based on roleId
            const filteredRoles = parsedUserData.filter(role => role.roleId == storedUserId);
            if (filteredRoles.length > 0) {
                // If there are roles with the specified roleId
                const filterRoleData = filteredRoles[0];
                setuseRoleData(filterRoleData); // Set the role data
            } else {
                // If there are no roles with the specified roleId
                console.log("No role data found for the specified roleId.");
            }
        }
    }, [useRoleId]);

    const [permissions, checkPermission] = useAuth();

    return (
        <>
            <div className="dashoboard">
                <SIDEBAR />

                <div className="component">
                    <HEADER />

                    <BREADCRUMB />

                    <div className="borrowers">
                        <INFO regDate={regDate} category={category} userType={userType} />

                        <div className="accountSummary mt-5 mb-3 pt-0 px-0 mb-5">
                            <h3 className="mb-3">Personal Details</h3>

                            <Row>
                                <Col lg="6">
                                    <Row>
                                        <Col lg="4">
                                            <div className="summaryBox mb-3">
                                                <h4>Full Legal Name</h4>
                                                <h5>{pepDetails?.firstName || ""} {pepDetails?.secondName || ""} {pepDetails?.thirdName || ""}</h5>
                                            </div>
                                        </Col>

                                        <Col lg="3">
                                            <div className="summaryBox mb-3">
                                                <h4>ID Number</h4>
                                                <h5>{pepDetails?.govId ? pepDetails?.govId : "N/A"}</h5>
                                            </div>
                                        </Col>

                                        <Col lg="3">
                                            <div className="summaryBox mb-3">
                                                <h4>Date of Birth</h4>
                                                <div className="dateTime">
                                                    <h5>{formatDate(pepDetails?.dob)}</h5>
                                                </div>
                                            </div>
                                        </Col>

                                        <Col lg="2">
                                            <div className="summaryBox mb-3">
                                                <h4>Place of Birth</h4>
                                                <h5>{pepDetails?.placeOfBirth ? pepDetails?.placeOfBirth : "N/A"}</h5>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg="6">
                                    <Row>
                                        <Col lg="1"></Col>

                                        <Col lg="2">
                                            <div className="summaryBox mb-3">
                                                <h4>Nationality</h4>
                                                <h5>{pepDetails?.nationality ? pepDetails?.nationality : "N/A"}</h5>
                                            </div>
                                        </Col>

                                        <Col lg="3">
                                            <div className="summaryBox mb-3">
                                                <h4>Contact</h4>
                                                <h5>{pepDetails?.phoneNumber ? pepDetails?.phoneNumber : "N/A"} </h5>
                                            </div>
                                        </Col>

                                        <Col lg="6">
                                            <div className="summaryBox mb-3">
                                                <h4>National Address</h4>
                                                <h5>{pepDetails?.address ? pepDetails?.address : "N/A"}</h5>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <hr />

                            <h3 className="mb-3">Occupation & Employer</h3>

                            <Row>
                                <Col lg="8">
                                    <Row>
                                        <Col lg="2">
                                            <div className="summaryBox mb-3">
                                                <h4>Sector</h4>
                                                <h5>{pepDetails?.sector ? pepDetails?.sector : "N/A"}</h5>
                                            </div>
                                        </Col>

                                        <Col lg="2">
                                            <div className="summaryBox mb-3">
                                                <h4>Job Title</h4>
                                                <h5>{pepDetails?.jobTitle ? pepDetails?.jobTitle : "N/A"}</h5>
                                            </div>
                                        </Col>

                                        <Col lg="2">
                                            <div className="summaryBox mb-3">
                                                <h4>Nature of Field</h4>
                                                <h5>{pepDetails?.natureOfField ? pepDetails?.natureOfField : "N/A"}</h5>
                                            </div>
                                        </Col>

                                        <Col lg="2">
                                            <div className="summaryBox mb-3">
                                                <h4>Date of Employment</h4>
                                                <div className="dateTime">
                                                    <h5>{pepDetails?.employmentDate ? formatDate(pepDetails?.employmentDate) : "N/A"}</h5>
                                                </div>
                                            </div>
                                        </Col>

                                        <Col lg="2">
                                            <div className="summaryBox mb-3">
                                                <h4>Salary</h4>
                                                <h5>{formattedNumber(pepDetails?.salary) ? formattedNumber(pepDetails?.salary) : "N/A"}</h5>
                                            </div>
                                        </Col>

                                        <Col lg="2">
                                            <div className="summaryBox mb-3">
                                                <h4>Education</h4>
                                                <h5>{pepDetails?.educationLevel ? pepDetails?.educationLevel : "N/A"}</h5>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <hr />

                            <h3 className="mb-3">Family Details</h3>

                            <Row>
                                <Col lg="8">
                                    <Row>
                                        <Col lg="2">
                                            <div className="summaryBox mb-3">
                                                <h4>Home Ownership</h4>
                                                <h5>{pepDetails?.houseOwnerShip ? pepDetails?.houseOwnerShip : "N/A"}</h5>
                                            </div>
                                        </Col>

                                        <Col lg="2">
                                            <div className="summaryBox mb-3">
                                                <h4>Type of Home</h4>
                                                <h5>{pepDetails?.typeOfHouse ? pepDetails?.typeOfHouse : "N/A"}</h5>
                                            </div>
                                        </Col>

                                        <Col lg="2">
                                            <div className="summaryBox mb-3">
                                                <h4>Marital Status</h4>
                                                <h5>{pepDetails?.maritalStatus ? pepDetails?.maritalStatus : "N/A"}</h5>
                                            </div>
                                        </Col>

                                        <Col lg="3">
                                            <div className="summaryBox mb-3">
                                                <h4>Number of Dependents</h4>
                                                <h5>{pepDetails?.numOfDependants ? pepDetails?.numOfDependants : "N/A"}</h5>
                                            </div>
                                        </Col>

                                        <Col lg="3">
                                            <div className="summaryBox mb-3">
                                                <h4>Number of Domestic  Labours</h4>
                                                <h5>{pepDetails?.numOfDomesticWorkers ? pepDetails?.numOfDomesticWorkers : "N/A"}</h5>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <hr />

                            <h3 className="mb-3">Source of Income</h3>

                            <Row>
                                <Col lg="8">
                                    <Row>
                                        <Col lg="2">
                                            <div className="summaryBox mb-3">
                                                <h4>Source of Income</h4>
                                                <h5>{pepDetails?.sourceOfIncome ? pepDetails?.sourceOfIncome : "N/A"}</h5>
                                            </div>
                                        </Col>

                                        <Col lg="2">
                                            <div className="summaryBox mb-3">
                                                <h4>Source of Wealth</h4>
                                                <h5>{pepDetails?.sourceOfWealth ? pepDetails?.sourceOfWealth : "N/A"}</h5>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <hr />

                            <h3 className="mb-3">PEP Responses</h3>

                            <Row>
                                <Col lg="8">
                                    <Row>
                                        {pepDetails?.userPepDetails?.map((response, index) => {

                                            return <Col lg="12" key={index}>
                                                <div className="summaryBox mb-3">
                                                    <h4>Q{index + 1}. {response.question}</h4>
                                                    <h5>{response.answer ? "Yes" : "No"}</h5>
                                                </div>
                                            </Col>


                                        })}
                                    </Row>
                                </Col>
                            </Row>

                            {
                                checkPermission({ subject: "app user management", action: "accept/reject pending approvals" }) ?
                                    <>
                                        <hr />

                                        <Row>
                                            <Col lg="5">
                                                <div className="accountAction-dv">
                                                    <Form action="">
                                                        <Row className="mb-3">
                                                            <Col lg="6">
                                                                <Form.Group className="mb-3" controlId="formGridState">
                                                                    <Form.Label>Account Action</Form.Label>

                                                                    <Form.Select defaultValue="Select your Account Action" onChange={HandleSelectUserPep}>
                                                                        <option className="light" value="">Select your Account Action</option>
                                                                        <option value="ban">Ban Profile</option>
                                                                        <option value="approve">Approve Profile</option>
                                                                    </Form.Select>
                                                                </Form.Group>
                                                            </Col>

                                                            <Col lg="12">
                                                                <Form.Group className="mb-3" controlId="formGridAddress2">
                                                                    <Form.Label>Comment</Form.Label>
                                                                    <Form.Control as="textarea" placeholder="Enter your Comment" value={comments} onChange={(e) => setComments(e.target.value)} />
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>

                                                        <Button variant="primary" type={userActionPep} onClick={handleShowUserPep}>
                                                            Apply
                                                        </Button>
                                                    </Form>

                                                    <Modal show={showUserPep} aria-labelledby="contained-modal-title-vcenter" centered className="userPepModal" onHide={handleCloseUserPep}>
                                                        <Modal.Body>
                                                            {
                                                                selectUserPep != "" ?
                                                                    <>
                                                                        <h3>Are you sure you want to <span style={{ textTransform: "capitalize" }}>{selectUserPep}</span> this user?</h3>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <h3>Please Select Account Action</h3>
                                                                    </>
                                                            }
                                                        </Modal.Body>
                                                        <Modal.Footer>
                                                            {
                                                                selectUserPep != "" ?
                                                                    <>
                                                                        <Button variant="secondary" onClick={handleActionUserPep}>
                                                                            {/* Approve */}
                                                                            <span style={{ textTransform: "capitalize" }}>{selectUserPep}</span>
                                                                        </Button>
                                                                        <Button variant="primary" onClick={handleCloseUserPep}>
                                                                            Cancel
                                                                        </Button>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <Button variant="primary" onClick={handleCloseUserPep}>
                                                                            Ok
                                                                        </Button>
                                                                    </>
                                                            }

                                                        </Modal.Footer>
                                                    </Modal>
                                                </div>
                                            </Col>
                                        </Row>
                                    </>
                                    :
                                    null
                            }

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PENDINGREGISTERATIONDETAIL