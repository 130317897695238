
import "../../assets/css/login.css";
import logo from "../../assets/images/logo.png";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../features/authUserSlice";
import { Link, useNavigate, useLocation } from "react-router-dom";
// import GlobalVar from "../../utilities/global/index";
import axios from 'axios'
import useGpsLocation from "../../hooks/location-hook";

const OTP = ({ setIsOtpVerified, route }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  // const [otpLength,setOtpLength] = useState(0);
  const [otp, setOtp] = useState('');
  const location = useLocation();
  const [resend, setResend] = useState({
    otpOrderId: location.state.otpOrderId,
    userId: location.state.userId
  })

  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const [resend, setResend] = useState(true);

  // const [inValidOtpCount, setInValidOtpCount] = useState(1);
  // const [resendOtpCount, setResendOtpCount] = useState(1);

  const timerIdRef = useRef();
  // console.log(timerIdRef);
  const [timerCount, setTimerCount] = useState(60);

  const resendOtpCount = useRef(0);

  ///////
  const [isTimerActive, setIsTimerActive] = useState(true);
  const [isTimerExpired, setIsTimerExpired] = useState(false);
  // const [isOtpCorrect, setIsOtpCorrect] = useState(false);
  // const [isOtpResent, setIsOtpResent] = useState(false);

  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const [userLocation, getUserLocation] = useGpsLocation();

  // const [displayTimerActiveMsg, setDisplayTimerActiveMsg] = useState(false);
  // const [displayTimerExpiredMsg, setDisplayTimerExpiredMsg] = useState(false);
  const [displayIncorrectOtpMsg, setDisplayIncorrectOtpMsg] = useState(false);
  const [displayResendOtpMsg, setDisplayResendOtpMsg] = useState(false);
  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(false);
  const [isOtpValueDisabled, setIsOtpValueDisabled] = useState(false);
  //////////

  useEffect(() => {
    timerIdRef.current = setInterval(() => {
      setTimerCount(prevCount => prevCount - 1);
    }, 1000);
    return () => clearInterval(timerIdRef.current);
  }, []);

  useEffect(() => {
    if (timerCount > 0) {
      // console.log(timerCount)
    } else {
      clearInterval(timerIdRef.current);

      setIsTimerActive(false);
      setIsTimerExpired(true);
      setIsResendDisabled(false);
      setIsSubmitBtnDisabled(true);
      setIsOtpValueDisabled(true);
      setDisplayResendOtpMsg(false);
      setDisplayIncorrectOtpMsg(false);
    }
  }, [timerCount]);

  const handleResend = async () => {
    setIsResendDisabled(true);
    setTimerCount(60);
    setIsTimerActive(true);
    setIsTimerExpired(false);
    setIsSubmitBtnDisabled(false);
    setIsOtpValueDisabled(false);
    setDisplayResendOtpMsg(true);
    setDisplayIncorrectOtpMsg(false);
    timerIdRef.current = setInterval(() => {
      setTimerCount(prevCount => prevCount - 1);
    }, 1000);


    try {
      const response = await axios({
        method: "POST",
        url: process.env.REACT_APP_BASE_URL + process.env.REACT_APP_ADMIN_LOGIN_RESEND_OTP,
        data: {
          otpOrderId: resend.otpOrderId,
          token: resend.token,
        }
      });
      if (response.statusCode === 200) {

      }
      if (response.data.statusCode === 406) {
        navigate("/exhaust-limit", { replace: true, state: { otp: true } });
      }
      console.log(response.data)
    } catch (error) {
      // console.log(error);
      // console.log("Now will be handling actions for three times otp resend failed");
      navigate("/exhaust-limit", { replace: true, state: { otp: true } });
    }




    // if (resendOtpCount.current < 2) {
    //   resendOtpCount.current++;
    //   console.log(resendOtpCount.current);
    // } else {

    //   // Handle max resend attempts UI here
    // }
    // // setError("resendMsg");




  }

  const handleSubmit = (e) => {

    getUserLocation()
    e.preventDefault();

    const credentials = {
      code: otp,
      otpId: location.state.otpId,
      userId: location.state.userId,
      lat: userLocation?.latitude || 0,
      lng: userLocation?.longitude || 0
    }

    dispatch(loginUser(credentials))
      .unwrap()
      .then((res) => {
        console.log('In then-success dispatch action: ', res)
        setIsOtpVerified(true);
        console.log(res?.scopes)
        if (res?.scopes?.includes("setup_pin")) {
          console.log(true)
          navigate("/auth-password", {
            replace: true,
            state: { userId: res?.userId, scopes: res?.scopes, userToken: res?.token }
          });
        } else {
          navigate(route, {
            replace: true,
            // state: { otp: true } 
          });
        }

      })
      .catch((error) => {
        const code = error.message.slice(-3);
        const message = error.message.slice(0, -4);

        // console.log('In error dispatch action: ', { message, code });
        if (code === "405" || code === "406") {
          console.log("code === 405 || code === 406");
          navigate("/exhaust-limit", { replace: true, state: { otp: true } });
        } else {
          setDisplayResendOtpMsg(false);
          setDisplayIncorrectOtpMsg(true);
        }

      });
  }

  const valueLength = 6;
  const value = otp;
  const onChange = (value) => setOtp(value);
  const RE_DIGIT = new RegExp(/^\d+$/);
  let timerInt;

  const valueItems = useMemo(() => {
    const valueArray = value.split('');
    const items = [];

    for (let i = 0; i < valueLength; i++) {
      const char = valueArray[i];

      if (RE_DIGIT.test(char)) {
        items.push(char);
      } else {
        items.push('');
      }
    }

    return items;
  }, [value, valueLength]);

  const focusToNextInput = (target) => {
    const nextElementSibling = target.nextElementSibling;

    if (nextElementSibling) {
      nextElementSibling.focus();
    }
  };


  const focusToPrevInput = (target) => {
    const previousElementSibling =
      target.previousElementSibling;

    if (previousElementSibling) {
      previousElementSibling.focus();
    }
  };


  const inputOnChange = (e, idx) => {
    const target = e.target;
    let targetValue = target.value.trim();
    const isTargetValueDigit = RE_DIGIT.test(targetValue);

    if (!isTargetValueDigit && targetValue !== '') {
      return;
    }

    const nextInputEl = target.nextElementSibling;

    // only delete digit if next input element has no value
    if (!isTargetValueDigit && nextInputEl && nextInputEl.value !== '') {
      return;
    }

    targetValue = isTargetValueDigit ? targetValue : ' ';

    const targetValueLength = targetValue.length;

    if (targetValueLength === 1) {
      const newValue =
        otp.substring(0, idx) + targetValue + otp.substring(idx + 1);

      onChange(newValue);

      if (!isTargetValueDigit) {
        return;
      }

      focusToNextInput(target);
    } else if (targetValueLength === valueLength) {
      onChange(targetValue);

      target.blur();
    }
  };

  const inputOnKeyDown = (e) => {
    const { key } = e;
    const target = e.target;

    if (key === 'ArrowRight' || key === 'ArrowDown') {
      e.preventDefault();
      return focusToNextInput(target);
    }

    if (key === 'ArrowLeft' || key === 'ArrowUp') {
      e.preventDefault();
      return focusToPrevInput(target);
    }

    const targetValue = target.value;

    // keep the selection range position
    // if the same digit was typed
    target.setSelectionRange(0, targetValue.length);

    if (e.key !== 'Backspace' || targetValue !== '') {
      return;
    }

    focusToPrevInput(target);
  };

  const inputOnFocus = (e) => {
    const { target } = e;

    // keep focusing back until previous input
    // element has value
    const prevInputEl = target.previousElementSibling;

    if (prevInputEl && prevInputEl.value === '') {
      return prevInputEl.focus();
    }

    target.setSelectionRange(0, target.value.length);
  };



  return (
    <>
      <div className="Login">
        <div className="container">
          <div className="row">
            <div className="col-md-3"></div>

            <div className="col-md-6">
              <div className="loginBox">
                <div className="loginBoxHeader">
                  <img src={logo} alt="logo" />
                </div>



                <div className="loginBoxContent">
                  {/* <div className={`loginBoxContent `}> */}
                  <form className="loginForm" action="" onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="mb-3">
                          <label htmlFor="" className="form-label otp" >
                            OTP
                          </label>

                          <p className="otpNote">
                            {/* A pin has been sent to your shared email address ar***@***il.com. */}
                            A pin has been sent to your shared email address <strong>{"otpEmail"}</strong>.
                            Please enter 6 digit pin to verify credentials.
                          </p>

                          <div className="otpInput">
                            {valueItems.map((digit, idx) => (
                              <input
                                key={idx}
                                id={`otp-input-${idx}`}
                                type="text"
                                inputMode="numeric"
                                autoComplete="one-time-code"
                                pattern="\d{1}"
                                maxLength={valueLength}
                                className="otp-input"
                                value={digit}
                                onChange={(e) => inputOnChange(e, idx)}
                                onKeyDown={inputOnKeyDown}
                                onFocus={inputOnFocus}
                                disabled={isOtpValueDisabled}
                              />
                            ))}
                          </div>
                          <div className='resendRow'>
                            <button type="button" className="resendButton" disabled={isResendDisabled} onClick={handleResend}>Resend OTP</button>
                            {
                              isTimerActive
                              && <p className='resendTimer'>Code Expires in {timerCount}s</p>
                            }
                            {
                              isTimerExpired
                              && <p className='resendTimer'>Code expired</p>
                            }
                          </div>

                        </div>
                      </div>

                      <div className="col-md-12 d-flex justify-content-center">
                        <button type="submit" className="btn btn-primary px-5" disabled={loading || isSubmitBtnDisabled}>
                          {loading ? "Loading..." : "Submit"}
                        </button>
                      </div>

                      {
                        displayIncorrectOtpMsg
                        && <div className={'col-md-12 errorRow  invalidMsg'}>
                          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 48 48">
                            <path d="M 24 4 C 12.972066 4 4 12.972074 4 24 C 4 35.027926 12.972066 44 24 44 C 35.027934 44 44 35.027926 44 24 C 44 12.972074 35.027934 4 24 4 z M 24 7 C 33.406615 7 41 14.593391 41 24 C 41 33.406609 33.406615 41 24 41 C 14.593385 41 7 33.406609 7 24 C 7 14.593391 14.593385 7 24 7 z M 24 14 A 2 2 0 0 0 24 18 A 2 2 0 0 0 24 14 z M 23.976562 20.978516 A 1.50015 1.50015 0 0 0 22.5 22.5 L 22.5 33.5 A 1.50015 1.50015 0 1 0 25.5 33.5 L 25.5 22.5 A 1.50015 1.50015 0 0 0 23.976562 20.978516 z"></path>
                          </svg>
                          <span className='invalidMsg'>Incorrect OTP entered</span>

                        </div>
                      }

                      {
                        displayResendOtpMsg
                        && <div className={'col-md-12 errorRow resendMsg'}>
                          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 48 48">
                            <path d="M 24 4 C 12.972066 4 4 12.972074 4 24 C 4 35.027926 12.972066 44 24 44 C 35.027934 44 44 35.027926 44 24 C 44 12.972074 35.027934 4 24 4 z M 24 7 C 33.406615 7 41 14.593391 41 24 C 41 33.406609 33.406615 41 24 41 C 14.593385 41 7 33.406609 7 24 C 7 14.593391 14.593385 7 24 7 z M 24 14 A 2 2 0 0 0 24 18 A 2 2 0 0 0 24 14 z M 23.976562 20.978516 A 1.50015 1.50015 0 0 0 22.5 22.5 L 22.5 33.5 A 1.50015 1.50015 0 1 0 25.5 33.5 L 25.5 22.5 A 1.50015 1.50015 0 0 0 23.976562 20.978516 z"></path>
                          </svg>

                          <span className='resendMsg'>New OTP has been sent!</span>
                        </div>
                      }


                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div className="col-md-3"></div>
          </div>
        </div>
      </div>

      {/* <div>
        {otp.map((digit, index) => (
        <input
            key={index}
            id={`otp-input-${index}`}
            type="text"
            maxLength="1"
            value={digit}
            onChange={(e) => handleOtpChange(index, e.target.value)}
        />
        ))}
    </div>
    <button onClick={() => console.log('Entered OTP:', otp.join(''))}>
        Verify OTP
    </button> */}
    </>
  );
};

export default OTP;
