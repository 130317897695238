import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api, getAPI } from "../../api/apiCallMethods";


const URL = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_OTHER_DETAILS;

export const showOtherDetails = createAsyncThunk(
    "user/otherDetails",
    async (params) => {
        const reqConfig = {
            method: "POST",
            url: URL,
            data: params
        };

        const response = await api(reqConfig);
        // console.log("Other Details Slice - URL & Params:", URL, params);

        // const response = await getAPI(URL, params);
        // console.log("Other Details Slice - response:", response);
        const otherDetailsPayload = await response.data.data;
        // console.log("Other Details Slice - payload:", otherDetailsPayload);

        return otherDetailsPayload;
    }
);

const showOtherDetailsSlice = createSlice({
    name: "userOtherDetails",
    initialState: {
        loading: false,
        otherDetails: null,
        error: null,
    },
    extraReducers: (builder) => {
        builder.addCase(showOtherDetails.pending, (state) => {
            state.loading = true;
            state.otherDetails = null;
            state.error = null;
        });
        builder.addCase(showOtherDetails.fulfilled, (state, action) => {
            state.loading = false;
            state.otherDetails = action.payload;
            state.error = null;
        });
        builder.addCase(showOtherDetails.rejected, (state, action) => {
            state.loading = false;
            state.otherDetails = null;
            state.error = action.error.message;
            console.log(action.error.message);
        });
    },
});
export const getOtherDetails = (state) => state.userOtherDetails;
export default showOtherDetailsSlice.reducer;
