import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api, postAPI } from "../../api/apiCallMethods";

const URL = process.env.REACT_APP_EWALLET_BASE_URL + process.env.REACT_APP_ALL_WALLETS_TRANSACTION_HISTORY;
// const URL = "http://localhost:8000/user/admin/wallet/transcation-history";

export const listAllWalletsTransactionHistory = createAsyncThunk(
    "allWallets/transactionHistory",
    async (data) => {
        const reqConfig = {
            method: "POST",
            url: URL,
            data
        };
        const response = await api(reqConfig);
        console.log("All Wallets Transaction History List Slice - URL & Params:", URL, data);
        // const response = await postAPI(URL, params)
        // const response = await getAPI('http://localhost:3004/get-data', params);
        // const allWalletsPayload = await response.data.data.;
        // console.log("All Wallets Transaction History List Slice - response./.././././.____:", response);
        const allWalletsTransactionHistoryPayload = await response.data.data;
        // console.log("All Wallets Transaction History List Slice - payload:", allWalletsTransactionHistoryPayload);
        return allWalletsTransactionHistoryPayload;
    }
);

const listAllWalletsTransactionHistorySlice = createSlice({
    name: "allWallets/transactionHistory",
    initialState: {
        loading: false,
        transactionHistory: null,
        error: null,
    },
    extraReducers: (builder) => {
        builder.addCase(listAllWalletsTransactionHistory.pending, (state) => {
            state.loading = true;
            state.transactionHistory = null;
            state.error = null;
        });
        builder.addCase(listAllWalletsTransactionHistory.fulfilled, (state, action) => {
            state.loading = false;
            state.transactionHistory = action.payload;
            state.error = null;
        });
        builder.addCase(listAllWalletsTransactionHistory.rejected, (state, action) => {
            state.loading = false;
            state.transactionHistory = null;
            state.error = action.error.message;
        });
    },
});

export const getAllWalletsTransactionHistoryList = (state) => state.allWalletsTransactionHistoryList;
export default listAllWalletsTransactionHistorySlice.reducer;

