import React, { useEffect } from "react";
import "../../assets/css/login.css";
import logo from "../../assets/images/logo.png";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../features/authUserSlice";
import { Link, useNavigate } from "react-router-dom";
// import GlobalVar from "../../utilities/global/index";
import axios from 'axios'
import useLocation from "../../hooks/location-hook";
import useGpsLocation from "../../hooks/location-hook";
const LOGIN = () => {
    localStorage.removeItem("userToken");
    localStorage.removeItem("userRoles");
    localStorage.removeItem("metaData");
    localStorage.removeItem("userId");
    const [typePass, setTypePass] = useState("password");
    const navigate = useNavigate();



    const view = () => {
        setTypePass(typePass === "password" ? "text" : "password");
    };

    // states
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [userLocation, getUserLocation] = useGpsLocation();

    // number length
    const [otpEmail, setOtpEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");


    const handleLoginEvent = async (e) => {
        getUserLocation()
        e.preventDefault();
        setLoading(true);
        let userCredentials = { email, password };

        try {
            if (!email && !password) {
                setLoading(false);
                throw new Error("Username and password are missing!");
            }
            if (!email) {
                setLoading(false);
                throw new Error("Username is missing!");
            }
            if (!password) {
                setLoading(false);
                throw new Error("Password is missing!");
            }
            const response = await axios({
                method: "POST",
                url: process.env.REACT_APP_BASE_URL + process.env.REACT_APP_ADMIN_LOGIN,
                headers: {
                    "ignore_encryption": true
                },
                data: {
                    ...userCredentials,
                    lat: userLocation?.latitude || 0,
                    lng: userLocation?.longitude || 0
                }
            });

            if (response.data.statusCode === 400) {
                console.log(response.data);
                throw new Error(response.data.message);
            }

            if (response.data.statusCode === 200) {
                navigate('/otp', { replace: true, state: { ...response.data.data } })
            }

            if (response.data.statusCode === 406) {  // 406 -  not acceptable
                navigate('/exhaust-limit', { replace: true, state: { password: true } })
            }

            if (response.data.statusCode === 405) {  // 406 -  not acceptable
                navigate('/exhaust-limit', { replace: true, state: { otp: true } })
            }

        } catch (error) {
            setLoading(false);
            setError(error.message)
            console.log(error);

        }
        setTimeout(() => {
            setError("")
        }, 5000)
    };



    return (
        <>
            <div className="Login">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3"></div>

                        <div className="col-md-6">
                            <div className="loginBox">
                                <div className="loginBoxHeader">
                                    <img src={logo} alt="logo" />
                                </div>

                                <div className="loginBoxContent">
                                    {/* <div className={`loginBoxContent d-none`}> */}
                                    <h2>Welcome Back</h2>
                                    <p>Please login using your account</p>

                                    <form className="loginForm" action="/dashboard" onSubmit={handleLoginEvent} >
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="mb-3">
                                                    <label htmlFor="exampleInputEmail1" className="form-label" >
                                                        User Name
                                                    </label>
                                                    <input
                                                        type="email"
                                                        className="form-control"
                                                        id="exampleInputEmail1"
                                                        placeholder="Enter your username"
                                                        aria-describedby="emailHelp"
                                                        value={email}
                                                        onChange={(e) => setEmail(e.target.value)}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-12">
                                                <div className="mb-3 inputBox">
                                                    <label htmlFor="exampleInputPassword1" className="form-label" >
                                                        Password
                                                    </label>
                                                    <input
                                                        type={typePass}
                                                        className="form-control"
                                                        placeholder="*********"
                                                        id="exampleInputPassword1"
                                                        value={password}
                                                        onChange={(e) => setPassword(e.target.value)}
                                                    />
                                                    <div className="viewIcon" onClick={view}>
                                                        <svg
                                                            width="18"
                                                            height="18"
                                                            viewBox="0 0 18 18"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M9 6.75C8.40326 6.75 7.83097 6.98705 7.40901 7.40901C6.98705 7.83097 6.75 8.40326 6.75 9C6.75 9.59674 6.98705 10.169 7.40901 10.591C7.83097 11.0129 8.40326 11.25 9 11.25C9.59674 11.25 10.169 11.0129 10.591 10.591C11.0129 10.169 11.25 9.59674 11.25 9C11.25 8.40326 11.0129 7.83097 10.591 7.40901C10.169 6.98705 9.59674 6.75 9 6.75ZM9 12.75C8.00544 12.75 7.05161 12.3549 6.34835 11.6517C5.64509 10.9484 5.25 9.99456 5.25 9C5.25 8.00544 5.64509 7.05161 6.34835 6.34835C7.05161 5.64509 8.00544 5.25 9 5.25C9.99456 5.25 10.9484 5.64509 11.6517 6.34835C12.3549 7.05161 12.75 8.00544 12.75 9C12.75 9.99456 12.3549 10.9484 11.6517 11.6517C10.9484 12.3549 9.99456 12.75 9 12.75ZM9 3.375C5.25 3.375 2.0475 5.7075 0.75 9C2.0475 12.2925 5.25 14.625 9 14.625C12.75 14.625 15.9525 12.2925 17.25 9C15.9525 5.7075 12.75 3.375 9 3.375Z"
                                                                fill="black"
                                                            />
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-12">
                                                <button type="submit" className="btn btn-primary w-100" disabled={loading} >
                                                    {loading ? "Loading..." : "Login"}
                                                </button>
                                                {error && (
                                                    <div className="alert alert-danger" role="alert">
                                                        {error}
                                                    </div>
                                                )}
                                            </div>

                                            {/* <a href="#!" className="linktext">
                                                Forgot your password?
                                            </a> */}
                                            <Link
                                                to="/forgot-password"
                                                className="linktext"
                                            >
                                                <div className="navTitle"> Forgot your password?</div>
                                            </Link>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>

                        <div className="col-md-3"></div>
                    </div>
                </div>
            </div>


        </>
    );
};

export default LOGIN;
