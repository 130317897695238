import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { decodeToken } from "react-jwt";
// import { postAPI } from "../api/apiCallMethods";
import axios from "axios";

export const loginUser = createAsyncThunk(
  "user/loginUser",
  async (userCredentials) => {
    // const response = await post(
    //   process.env.REACT_APP_LOGIN_AUTHENTICATION_API,
    //   userCredentials
    // );

    // console.log("The user credentials are as follows....", userCredentials);
    // console.log(userCredentials.code.length)

    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_BASE_URL + process.env.REACT_APP_ADMIN_LOGIN_VERIFY,
      headers: {
        "ignore_encryption": true
      },
      data: {
        ...userCredentials
      }
    });
    // console.log(response.data);
    // ======================================================================
    if (response.data.statusCode === 400
      || response.data.statusCode === 405 // method not allowed: otp time limit ended
      || response.data.statusCode === 406 // not acceptible: otp attempt limit exceeded
    ) {
      throw {
        message: response.data.message + ` ${response.data.code}`
      };
    }
    const token = await response.data.data.token;
    // ======================================================================



    // const roleId = await response.data.data.roleId;
    // const userName = await response.data.data.name;
    // ======================================================================
    // const metaData = await response.data.data.metaData;
    const userId = userCredentials.userId;
    // ======================================================================
    // console.log("Login Data", response.data.data);
    // console.log("This is the token in auth user slice:::", token);

    // const token = await response.accessToken;
    const data = decodeToken(token);
    // ======================================================================

    if (data?.scopes?.includes("setup_pin") !== true) {
      localStorage.setItem("userToken", token);
      localStorage.setItem("userId", userId);
    }

    // ======================================================================

    // localStorage.setItem("userRoles", JSON.decode(roleData));


    // ======================================================================
    // ======================================================================

    // localStorage.setItem("userRoleId", roleId);
    // localStorage.setItem("userName", userName);

    // ======================================================================
    // localStorage.setItem("metaData", JSON.stringify(metaData));
    // ======================================================================

    // decoding token to return the dynamic response to store it into the state of
    //user.  user:{name:"Khalid", role:"super admin"}
    console.log("TOKEN DATA:" + JSON.stringify(data))
    const { name, role } = data

    return {
      name: name ? name : "Test Name",
      role: role ? role : "Super Admin",
      scopes: data?.scopes || [],
      token,
      userId
    };
  }
);

const authUserSlice = createSlice({
  name: "authUser",
  initialState: {
    loading: false,
    user: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(loginUser.pending, (state) => {
      state.loading = true;
      state.user = null;
      state.error = null;
    });
    builder.addCase(loginUser.fulfilled, (state, action) => {
      state.loading = false;
      state.user = action.payload;
      state.error = null;
    });
    builder.addCase(loginUser.rejected, (state, action) => {
      state.loading = false;
      state.user = null;
      if (action.error.message === "Request failed with status code 401") {
        state.error = "Access Denied! Invalid Credentials";
      } else if (action.error.message === "Request failed with status code 500") {
        console.log("Request failed with status code 500");
        state.error = "Server Error!";
      } else {
        state.error = action.error.message;
      }
    });
  },
});

export default authUserSlice.reducer;
