import React, { useState, useEffect } from "react";
import "../../assets/css/login.css";
import logo from "../../assets/images/logo.png";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../features/authUserSlice";
import { Link, useNavigate, useLocation } from "react-router-dom";
import GlobalVar from "../../utilities/global/index";
import axios from "axios";
import { jwtDecode } from "jwt-decode";


const AUTHPASSWORD = () => {

    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}|:<>?~`\-=[\]\\;',./]).{8,14}$/;

    const [typePass, setTypePass] = useState("password");


    // states
    const [newPassword, setNewPassword] = useState("");
    const [confrimPassword, setConfrimPassword] = useState("");

    // OTP
    const view = () => {
        setTypePass(typePass === "password" ? "text" : "password");
    };

    // redux state
    const [error, setError] = useState("");
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const isNewPasswordValid = passwordRegex.test(newPassword) ? true : false;
    const isConfirmPasswordValid = newPassword === confrimPassword ? true : false;
    const token = location.state?.userToken;
    const userId = location.state?.userId;

    let decoded = undefined;

    const isValidJWT = (token) => {
        // Regular expression to match JWT token format
        const jwtRegex = /^[A-Za-z0-9-_]+\.[A-Za-z0-9-_]+\.[A-Za-z0-9-_.+/=]+$/;

        return jwtRegex.test(token);
    };

    useEffect(() => {
        if (!token) {
            navigate("/", { replace: true });
        } else {
            if (isValidJWT(token)) {
                decoded = jwtDecode(token);
                console.log({ decoded });
            } else {
                navigate("/", { replace: true });
            }
        }
    }, [])




    const handleAuthPass = async (e) => {
        e.preventDefault();
        try {
            if (isNewPasswordValid && isConfirmPasswordValid) {
                const response = await axios({
                    method: "POST",
                    url: process.env.REACT_APP_BASE_URL + process.env.REACT_APP_ADMIN_RENEW_PASSWORD,
                    headers: {
                        "x-user-id": userId,
                        "Authorization": `Bearer ${token}`
                    },
                    data: {
                        lat: 0,
                        lng: 0,
                        password: newPassword
                    }
                });
                console.log(response);
                setNewPassword("");
                setConfrimPassword("");
                navigate("/", { replace: true });
            } else {
                throw new Error("Please enter valid passwords!");
            }

        } catch (err) {
            console.error(err);
            setNewPassword("");
            setConfrimPassword("");
            setError(err.message)

        }
    };
    useEffect(() => {
        if (passwordRegex.test(newPassword)) {
            setError("")
            console.log("Password is Valid");
            if (newPassword !== confrimPassword) {
                setError("Both passwords should match.")
            } else {
                setIsSubmitDisabled(false);
            }
        } else {
            setError("Password must contain at least one lowercase letter, one uppercase letter, one digit, one special character, and be between 8 and 14 characters long.")
        }
    }, [newPassword, confrimPassword])


    return (
        <>
            <div className="Login">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3"></div>

                        <div className="col-md-6">
                            <div className="loginBox">
                                <div className="loginBoxHeader">
                                    <img src={logo} alt="logo" />
                                </div>

                                <div className={`loginBoxContent `}>
                                    {/* <div className={`loginBoxContent d-none`}> */}
                                    <h2>Password Reset</h2>
                                    <p>Change your password and login into your account</p>

                                    <form className="loginForm"
                                        // action="/dashboard" 
                                        onSubmit={handleAuthPass} >
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="mb-3">
                                                    <label className="form-label" >
                                                        New Password
                                                    </label>
                                                    <input
                                                        type={typePass}
                                                        className="form-control"
                                                        id="newPassword"
                                                        placeholder="*********"
                                                        aria-describedby="emailHelp"
                                                        value={newPassword}
                                                        onChange={(e) => setNewPassword(e.target.value)}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-12">
                                                <div className="mb-3 inputBox">
                                                    <label className="form-label" >
                                                        Confirm Password
                                                    </label>
                                                    <input
                                                        type={typePass}
                                                        className="form-control"
                                                        placeholder="*********"
                                                        id="confirmPassword"
                                                        value={confrimPassword}
                                                        onChange={(e) => setConfrimPassword(e.target.value)}
                                                    />
                                                    <div className="viewIcon" onClick={view}>
                                                        <svg
                                                            width="18"
                                                            height="18"
                                                            viewBox="0 0 18 18"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M9 6.75C8.40326 6.75 7.83097 6.98705 7.40901 7.40901C6.98705 7.83097 6.75 8.40326 6.75 9C6.75 9.59674 6.98705 10.169 7.40901 10.591C7.83097 11.0129 8.40326 11.25 9 11.25C9.59674 11.25 10.169 11.0129 10.591 10.591C11.0129 10.169 11.25 9.59674 11.25 9C11.25 8.40326 11.0129 7.83097 10.591 7.40901C10.169 6.98705 9.59674 6.75 9 6.75ZM9 12.75C8.00544 12.75 7.05161 12.3549 6.34835 11.6517C5.64509 10.9484 5.25 9.99456 5.25 9C5.25 8.00544 5.64509 7.05161 6.34835 6.34835C7.05161 5.64509 8.00544 5.25 9 5.25C9.99456 5.25 10.9484 5.64509 11.6517 6.34835C12.3549 7.05161 12.75 8.00544 12.75 9C12.75 9.99456 12.3549 10.9484 11.6517 11.6517C10.9484 12.3549 9.99456 12.75 9 12.75ZM9 3.375C5.25 3.375 2.0475 5.7075 0.75 9C2.0475 12.2925 5.25 14.625 9 14.625C12.75 14.625 15.9525 12.2925 17.25 9C15.9525 5.7075 12.75 3.375 9 3.375Z"
                                                                fill="black"
                                                            />
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-12">
                                                <button type="submit" className="btn btn-primary w-100" disabled={isSubmitDisabled || error !== "" ? true : false} >
                                                    Submit
                                                </button>
                                                {error && (
                                                    <div className="alert alert-danger" role="alert">
                                                        {error}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>

                        <div className="col-md-3"></div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AUTHPASSWORD;
