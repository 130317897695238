import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api, getAPI } from "../../api/apiCallMethods";

const URL = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_PEP_LISTING;

export const listPeps = createAsyncThunk(
    "pep/listPep",
    async (body) => {
        const reqConfig = {
            method: "POST",
            url: URL,
            data: body
        };
        const response = await api(reqConfig);
        // console.log("List PEP Slice - URL & Params:", URL, params);
        // const response = await getAPI(URL, params)
        // const response = await getAPI('http://localhost:3004/get-data', params);
        // console.log("List PEP Slice - response:", response);
        const pepPayload = await response.data.data;
        // const pepPayload = await response.data;
        // console.log("List PEP Slice - payload:", pepPayload);
        return pepPayload;
    }
);

const listPepSlice = createSlice({
    name: "listPep",
    initialState: {
        loading: false,
        peps: null,
        error: null,
    },
    extraReducers: (builder) => {
        builder.addCase(listPeps.pending, (state) => {
            state.loading = true;
            state.peps = null;
            state.error = null;
        });
        builder.addCase(listPeps.fulfilled, (state, action) => {
            state.loading = false;
            state.peps = action.payload;
            state.error = null;
        });
        builder.addCase(listPeps.rejected, (state, action) => {
            state.loading = false;
            state.peps = null;
            state.error = action.error.message;
        });
    },
});

export const getPepList = (state) => state.peps;
export default listPepSlice.reducer;
