import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api, getAPI } from "../../api/apiCallMethods";

const URL = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_PEP_DETAILS;

export const showPepDetails = createAsyncThunk(
  "pepDetails/showPepDetails",
  async (params) => {
    const reqConfig = {
      method: "POST",
      url: URL,
      data: params
    };
    const response = await api(reqConfig);
    // console.log("PEP Details Slice - URL & Params:", URL, params);

    // const response = await getAPI(URL, params)
    // console.log("PEP Details Slice - response:", response);

    const pepDetailsPayload = response.data.data

    console.log("PEP Details Slice - payload:", pepDetailsPayload);

    return pepDetailsPayload;
  }
);

const showPepDetailsSlice = createSlice({
  name: "showPepDetailsSlice",
  initialState: {
    loading: false,
    pepDetails: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(showPepDetails.pending, (state) => {
      state.loading = true;
      state.pepDetails = null;
      state.error = null;
    });
    builder.addCase(showPepDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.pepDetails = action.payload;
      state.error = null;
    });
    builder.addCase(showPepDetails.rejected, (state, action) => {
      state.loading = false;
      state.pepDetails = null;
      state.error = action.error.message;
      // console.log(action.error.message);
    });
  },
});
export const getPepDetails = (state) => state.pepDetails;
export default showPepDetailsSlice.reducer;
