import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api, getAPI } from "../../api/apiCallMethods";


const URL = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_USER_ANALYTICS

    ;

export const showLenderAnalytics = createAsyncThunk(
    "lender/analytics",
    async (params) => {
        const reqConfig = {
            method: "POST",
            url: URL,
            data: params
        };
        const response = await api(reqConfig);
        // console.log("Borrower Counter Slice - URL & Params:", URL);
        // const response = await getAPI(URL, params);
        // console.log("Borrower Counter Slice - response:", response);
        const analyticsPayload = await response.data.data;
        // console.log("Borrower Counter Slice - payload:", analyticsPayload);

        return analyticsPayload;
    }
);

const lenderAnalyticsSlice = createSlice({
    name: "lenderAnalytics",
    initialState: {
        loading: false,
        analytics: null,
        error: null,
    },
    extraReducers: (builder) => {
        builder.addCase(showLenderAnalytics.pending, (state) => {
            state.loading = true;
            state.analytics = null;
            state.error = null;
        });
        builder.addCase(showLenderAnalytics.fulfilled, (state, action) => {
            state.loading = false;
            state.analytics = action.payload;
            state.error = null;
        });
        builder.addCase(showLenderAnalytics.rejected, (state, action) => {
            state.loading = false;
            state.analytics = null;
            state.error = action.error.message;
        });
    },
});
export const getLenderAnalytics = (state) => state.lenderAnalytics;
export default lenderAnalyticsSlice.reducer;
