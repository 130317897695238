import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api, postAPI } from "../../api/apiCallMethods";

const URL = process.env.REACT_APP_EWALLET_BASE_URL + process.env.REACT_APP_PLATFORM_WALLETS_TRANSACTION_HISTORY;

export const listPlatformWalletsTransactionHistory = createAsyncThunk(
    "platformWallets/transactionHistory",
    async (data) => {
        const reqConfig = {
            method: "POST",
            url: URL,
            data
        };
        const response = await api(reqConfig);
        // console.log("Platform Wallets Transaction History List Slice - URL & data:", URL, data);
        // const response = await postAPI(URL, data);
        // const response = await getAPI('http://localhost:3004/get-data', data);
        // console.log("Platform Wallets Transaction History List Slice - response:", response);
        const platformWalletsTransactionHistoryPayload = await response.data.data;
        // console.log("Platform Wallets Transaction History List Slice - payload:", platformWalletsTransactionHistoryPayload);
        return platformWalletsTransactionHistoryPayload;
    }
);

const listPlatformWalletsTransactionHistorySlice = createSlice({
    name: "platformWallets/transactionHistory",
    initialState: {
        loading: false,
        transactionHistory: null,
        error: null,
    },
    extraReducers: (builder) => {
        builder.addCase(listPlatformWalletsTransactionHistory.pending, (state) => {
            state.loading = true;
            state.transactionHistory = null;
            state.error = null;
        });
        builder.addCase(listPlatformWalletsTransactionHistory.fulfilled, (state, action) => {
            state.loading = false;
            state.transactionHistory = action.payload;
            state.error = null;
        });
        builder.addCase(listPlatformWalletsTransactionHistory.rejected, (state, action) => {
            state.loading = false;
            state.transactionHistory = null;
            state.error = action.error.message;
        });
    },
});

export const getPlatformWalletsTransactionHistoryList = (state) => state.platformWalletsTransactionHistoryList;
export default listPlatformWalletsTransactionHistorySlice.reducer;

